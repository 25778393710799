@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
h1, h2, h3, h4, p,li{
    font-family: 'Poppins', sans-serif;
}

.header{
    background-image:url(../imagenes/vetzooHeader.png);

    background-size:cover;
}
