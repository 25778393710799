.aboutl {
  animation: movel 2s ease-in-out;
}
.aboutr {
  animation: mover 2s ease-in-out;
}


@keyframes movel {
  0%
  { 
    transform: translateX(10%); 
    opacity: 0.3;
  }
  100%
  { 
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes mover {
  0%
  { 
    transform: translateX(-20%); 
    opacity: 0.3;
  }
  100%
  { 
    transform: translateX(0%);
    opacity: 1;
  }
}